body {
  background-color: #282c34;
  color: #eee;
}

.App {
  text-align: center;
}

main {
  margin: 2rem auto;
  width: 480px;
}

.domain {
  color: #ccc;
}

button {
  margin: 4px;
  width: 120px;
  font-size: 1rem;
  border-radius: 8px;
  &.primary {
    background-color: royalblue;
    border: 1px solid royalblue;
    color: white;
  }
  &.secondary {
    background-color: #999;
    border: 1px solid #999;
  }
}
